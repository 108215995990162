import TranslationService from "../../../Ceres/resources/js/src/app/services/TranslationService";

Vue.component('my-account-savedcart', {
    delimiters: ["${", "}"],
    props: ['template','hashvalue','userid'],
    data: function()
    {
        return {
            items: [],
            user:[],
            deleteItemsInBasket: false,
            waiting:false
        };
    },
    mounted: function()
    {
        var _self = this;
        this.giftItems();
    },
    methods: {
        giftItems: function()
        {
            var _self = this;

            if(this.hashvalue)
            {
                return $.ajax({
                    type:           'GET',
                    url:            '/cartsave/user/public/'+this.hashvalue,
                    dataType:       'json',
                    success:        function(data)
                    {
                        for( var i = 0; i < data.length; i++)
                        {

                            data[i].cartURL = document.location.origin+'/cartlist/'+data[i].cartURL+'/';

                            if(data[i].cartPublic==="0")
                            {
                                data[i].cartPublic = false;
                            }
                            else
                            {
                                data[i].cartPublic = true;
                            }
                        }
                        _self.items = data;
                    },
                    error:          function(jqXHR, textStatus, errorThrown)
                    {
                        console.error(errorThrown);
                    }
                });
            }
            else
            {
                return $.ajax({
                    type:           'GET',
                    url:            '/cartsave/user/'+this.userid,
                    dataType:       'json',
                    success:        function(data)
                    {
                        for( var i = 0; i < data.length; i++)
                        {
                            data[i].cartURL = document.location.origin+'/cartlist/'+data[i].cartURL+'/';
                            if(data[i].cartPublic==="0")
                            {
                                data[i].cartPublic = false;
                            }
                            else
                            {
                                data[i].cartPublic = true;
                            }
                        }
                        _self.items = data;
                    },
                    error:          function(jqXHR, textStatus, errorThrown)
                    {
                        console.error(errorThrown);
                    }
                });
            }
        },
        cartDelete: function(id)
        {
            var _self = this;
            for( var i = 0; i < this.items.length; i++)
            {
                if ( this.items[i].cartId === id)
                {
                    this.items.splice(i, 1);
                }
            }
            $.ajax({
                type: 'POST',
                url: '/cartsave/delete/',
                data: {"id": id},
                success: function (data) {
                    CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesWarenkorbgeloescht")).closeAfter(3000);

                },
                error: function (jqXHR, textStatus, errorThrown) {
                    CeresNotification.error('jqXHR'+jqXHR+'textStatus'+textStatus+'errorTrown:'+errorThrown);
                }
            })
        },
        savedCartItemToBasket: function(item)
        {
            var basketObject =
            {
                variationId             :   item.id,
                quantity                :   item.quantity
            };
            ceresStore.dispatch("addBasketItem", basketObject)
        },
        listToBasket: function(cart,jumptocheckout = false)
        {
            let _self = this;
            _self.waiting = true;
            if(_self.deleteItemsInBasket === true)
            {
                _self.deleteItemsInBasket = false;
                _self.listToBasketDeleteOld(cart)
            }
            else
            {
                var variations = [];
                for( var i = 0; i < cart.length; i++)
                {
                    variations.push(
                        {
                            "variationId": cart[i].id,
                            "quantity":cart[i].quantity,
                            "name":cart[i].data.texts.name1
                        });
                }
                var data = {
                    "items": variations
                }
                $.ajax({
                    type: 'POST',
                    url: '/cartsave/addtobasket/',
                    data: data,
                    success: function (data)
                    {
                        for( var i = 0; i < data.length; i++)
                        {
                            if(data[i].error)
                            {
                                CeresNotification.error(TranslationService.translate("YbbaCartSave::Template.notesNichtgenugbestand",
                                    {
                                        variationName: data[i].error.variationName,
                                        stock: data[i].error.placeholder.stock
                                    })
                                );
                            }
                            else {
                                CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesArtikelhinzugefuegt")).closeAfter(3000);
                                if (jumptocheckout == true)
                                {
                                    window.location.href = App.urls.checkout;
                                }
                            }
                            if(i===data.length-1)
                            {
                                vueApp.$store.commit("setBasketItems", data[i].basketItems);
                                vueApp.$store.commit("setBasket", data[i].basket);
                            }
                        }
                        _self.waiting = false;
                    },
                    error: function (jqXHR, textStatus, errorThrown) {
                        console.log(jqXHR);
                    }
                })
            }
        },
        deleteItemFromSavedCart: function (cart,index)
        {
            cart.items.splice(index,1);
            var data = {
                "id":cart.cartId,
                "customerId":this.userid,
                "cartName":cart.cartName,
                "variations":cart.items
            }
            $.ajax(
            {
                type: 'POST',
                url: '/cartsave/update/',
                data: data,
                success: function (response)
                {
                    CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesWarenkorbUpdate",{ cartName: cart.cartName })).closeAfter(3000);
                },
                error: function (jqXHR)
                {
                    console.log(jqXHR);
                }
            })

        },
        deletePublic: function (cart,index)
        {
            cart.items.splice(index,1);
        },
        updateCart: function (cart)
        {
            console.log(cart);
            var data = {
                "id":cart.cartId,
                "customerId":this.userid,
                "cartName":cart.cartName,
                "cartPublic": cart.cartPublic,
                "variations":cart.items
            }
            $.ajax(
                {
                    type: 'POST',
                    url: '/cartsave/update/',
                    data: data,
                    success: function (response)
                    {
                        CeresNotification.success(TranslationService.translate("YbbaCartSave::Template.notesWarenkorbUpdate",{ cartName: cart.cartName })).closeAfter(3000);
                    },
                    error: function (jqXHR)
                    {
                        console.log(jqXHR);
                    }
                })

        },
        listToBasketDeleteOld: function(items,jumptobasket = false)
        {
            var _self = this;
            $.ajax(
                {
                    type: 'POST',
                    url: '/cartsave/basket/delete/',
                    success: function (response)
                    {
                        _self.listToBasket(items,jumptobasket)
                    },
                    error: function (jqXHR)
                    {
                        console.log(jqXHR);
                    }
                });
        },
        addVariationToList: function (cart)
        {
            let _self = this;
            _self.waiting = true;

            $.ajax(
            {
                type: 'GET',
                url: 'cartsave/cart/getitem/'+cart.items.newItem,
                success: function (response)
                {
                    response.quantity = "1";
                    console.log(response);
                    cart.items.push(response);
                    _self.updateCart(cart)
                    delete carts.newItem;
                },
                error: function (jqXHR)
                {
                    console.log(jqXHR);
                }
            });
        },
        changeVariation: function (variation)
        {
            console.log(variation);
        }
    },
    computed:
        {
            allGiftItems: function()
            {
                var _self = this;
                return this.items;
            }
        }
});